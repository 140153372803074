<template>
    <div class="text-game-container" :class="{'mobile': !desktop}">
        <TransitionerObject name="fade-and-blur-normal">
            <div class="title-screen" v-if="onTitle">
                <span class="title-text" v-html="gameData.title || 'Practice Title'"/>
                <div class="start-button" tabindex="0" role="button" @click="beginGame" v-on:keydown.enter="beginGame" v-if="canStart">
                    <span v-html="translate('game-start')"/>
                </div>
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-and-blur-normal">
            <div class="game-screen" v-if="onGame">
                <div class="information-display" :class="{'mobile': !desktop}">
                    <div class="dialogue-display">
                        <div class="dialogue-panel" :class="{'mobile': !desktop}">
                            <span v-html="displayText"/>
                        </div>
                    </div>
                    <div class="inventory-container" v-if="inventory.length > 0" :class="{'mobile': !desktop}">
                        <div class="inventory-panel">
                            <span class="inventory-header" v-html="translate('inventory')" :class="{'mobile': !desktop}"/>
                            <div class="inventory-display" :class="{'mobile': !desktop}">
                                <div class="inventory-button" v-for="item in inventory" :key="item" tabindex="0" role="button" @click="useItem(item)" v-on:keydown.enter="useItem(item)">
                                    <span v-html="item"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="options-display" v-if="canContinue && (currentDialogue.choices || currentDialogue.next)">
                    <span class="options-title" v-if="currentDialogue.choices && currentDialogue.choices.length > 0" v-html="translate('game-choices')"/>
                    <div class="options-arrange" :class="{'mobile': !desktop}">
                        <div class="options-button" v-for="choice in currentDialogue.choices" :key="choice.next" :style="{display: validateChoice(choice) ? 'block': 'none'}" tabindex="0" role="button" @click="queueDialogue(choice.next)" v-on:keydown.enter="queueDialogue(choice.next)">
                            <span v-html="choice.name"/>
                        </div>
                        <div class="options-button" v-if="(currentDialogue.choices === null || currentDialogue.choices === undefined || currentDialogue.choices.length === 0) && currentDialogue.next" tabindex="0" role="button" @click="queueDialogue(currentDialogue.next)" v-on:keydown.enter="queueDialogue(currentDialogue.next)">
                            <span v-html="translate('game-continue')"/>
                        </div>
                    </div>
                </div>
                <div class="skip-button" v-if="canSkip" @click="skipDialogueRender" tabindex="0" role="button" v-on:keydown.enter="skipDialogueRender"/>
                <TransitionerObject name="fade-normal">
                    <div class="cant-use" v-if="cantUseMessage">
                        <div class="banner">
                            <span v-html="translate('game-cant-use')" />
                        </div>
                    </div>
                </TransitionerObject>
            </div>
        </TransitionerObject>
    </div>
</template>
<script>
    import TransitionerObject from './TransitionerObject.vue';
    import { mapGetters } from 'vuex';
    export default {
        name: "TextGame",
        components:
        {
            TransitionerObject
        },
        data: function () {
            return {
                gameData: {},
                gameStartTimeout: null,
                textRenderTimeout: null,
                textRenderIndex: 0,
                displayText: "",
                renderText: "",
                canStart: false,
                onTitle: true,
                onGame: false,
                canContinue: false,
                canSkip: false,
                cantUseMessage: false,
                inventory: [],
                knowledge: [],
                currentDialogue: {},
                cheevosTimeout: null,
                musicbox: null,
                currentTrack: "SILENCE",
                songFadeTimeout: null
            };
        },
        mounted: async function ()
        {
            await fetch('./assets/data/' + this.game + '.json').then((response) => { return response.json() }).then((data) => { this.gameData = data; this.canStart = true;});
            this.cheevosTimeout = setTimeout(function(){this.dispatchAchievement("gamer-moment");}.bind(this), 500);
        },
        unmounted: async function ()
        {
            if (this.gameStartTimeout) clearTimeout(this.gameStartTimeout);
            if (this.textRenderTimeout) cancelAnimationFrame(this.textRenderTimeout);
            if (this.cheevosTimeout) clearTimeout(this.cheevosTimeout);
            if (this.musicbox) this.musicbox.pause();
            if (this.songFadeTimeout) cancelAnimationFrame(this.songFadeTimeout);

        },
        methods: {
            beginGame: function ()
            {
                this.onTitle = false;
                this.gameStartTimeout = setTimeout(function () { this.queueDialogue("start"); this.gameStartTimeout = null; this.onGame = true; }.bind(this), 1500);
            },
            fadeSong: function ()
            {
                if (this.musicbox.volume > 0.02) this.musicbox.volume -= 0.01;
                else {
                    this.musicbox.pause();
                    this.songFadeTimeout = null;
                    return;
                }
                this.songFadeTimeout = requestAnimationFrame(this.fadeSong.bind(this));
            },
            queueDialogue: function (key) {
                if (key === "start")
                {
                    this.inventory = [];
                    this.knowledge = [];
                }
                this.canContinue = false;
                this.dialogueEnded = false;
                this.displayText = "";
                this.textRenderIndex = 0;
                this.currentDialogue = this.gameData.dialogue[key];
                if (this.currentDialogue.conditional) this.currentDialogue = this.validateDialogue(this.currentDialogue);
                let lines = this.currentDialogue.text;
                this.renderText = "";
                for (let l = 0; l < lines.length; l++)
                {
                    if (l > 0) this.renderText += "\n\n";
                    this.renderText += lines[l];
                }
                let rem = this.currentDialogue.remove;
                if (rem) {
                    for (let r = 0; r < rem.length; r++) {
                        let itemIndex = this.inventory.indexOf(rem[r]);
                        if (itemIndex >= 0) {
                            this.inventory.splice(itemIndex, 1);
                        }
                    }
                }
                let unl = this.currentDialogue.unlearn;
                if (unl) {
                    for (let u = 0; u < unl.length; u++) {
                        let knowIndex = this.knowledge.indexOf(unl[u]);
                        if (knowIndex >= 0) {
                            this.knowledge.splice(knowIndex, 1);
                        }
                    }
                }
                this.canSkip = true;
                if (this.currentDialogue.achievement){
                    let unlock = true;
                    if (this.currentDialogue.achievement.conditions){
                        for (let c = 0; c < this.currentDialogue.achievement.conditions.length; c++){
                            if (!this.knowledge.includes(this.currentDialogue.achievement.conditions[c])) {
                                unlock = false;
                                break;
                            }
                        }
                    }
                    if (unlock) this.dispatchAchievement(this.currentDialogue.achievement.key, this.gameData["achievement-title"]);
                }
                if (this.currentDialogue.unskippable) this.canSkip = false;
                if (this.currentDialogue.track !== null && this.currentDialogue.track !== undefined) {
                    let track = this.currentDialogue.track;
                    if (track === "SILENCE" && this.musicbox) { this.songFadeTimeout = requestAnimationFrame(this.fadeSong.bind(this)); }
                    else {
                        if (this.musicbox === null) this.musicbox = new Audio();
                        if (this.currentTrack !== track) {
                            if (this.songFadeTimeout) {
                                cancelAnimationFrame(this.songFadeTimeout);
                                this.songFadeTimeout = null;
                            }
                            this.musicbox.currentTime = 0;
                            this.musicbox.volume = 1;
                            this.musicbox.src = "./assets/audio/" + track + ".mp3";
                            this.musicbox.loop = true;
                            this.musicbox.play();
                        }
                    }
                    this.currentTrack = track;
                }
                this.skipAttempts = 0;
                requestAnimationFrame(this.renderDialogue.bind(this));
            },
            renderDialogue: function () {
                this.displayText += this.renderText[this.textRenderIndex];
                if (this.textRenderIndex === this.renderText.length - 1) {
                    this.endDialogueRender();
                    return;
                }
                this.textRenderIndex++;
                this.textRenderTimeout = requestAnimationFrame(this.renderDialogue.bind(this));
            },
            endDialogueRender: function ()
            {
                this.textRenderTimeout = null;
                this.canSkip = false;
                if (this.currentDialogue.gain)
                {
                    let inv = this.currentDialogue.gain.inventory;
                    if (inv) {
                        for (let i = 0; i < inv.length; i++) {
                            if (this.inventory.indexOf(inv[i]) === -1) this.inventory.push(inv[i]);
                        }
                    }
                    let know = this.currentDialogue.gain.knowledge;
                    if (know) {
                        for (let k = 0; k < know.length; k++) {
                            if (this.knowledge.indexOf(know[k]) === -1) this.knowledge.push(know[k]);
                        }
                    }
                }
                this.canContinue = true;
            },
            skipDialogueRender: function ()
            {
                cancelAnimationFrame(this.textRenderTimeout);
                this.displayText = this.renderText;
                this.endDialogueRender();
            },
            useItem: function (item) {
                if (!this.canContinue) { return; }
                if (this.currentDialogue.use)
                {
                    let possible = this.currentDialogue.use
                    for (let p = 0; p < possible.length; p++)
                    {
                        if (possible[p].item === item) {
                            if (possible[p].remove)
                            {
                                let rem = possible[p].remove;
                                for (let r = 0; r < rem.length; r++)
                                {
                                    let itemIndex = this.inventory.indexOf(rem[r]);
                                    if (itemIndex >= 0)
                                    {
                                        this.inventory.splice(itemIndex, 1);
                                    }
                                }
                            }
                            this.queueDialogue(possible[p].next);
                            return;
                        }
                    }
                }
                if (!this.cantUseMessage) {
                    this.cantUseMessage = true;
                    this.gameStartTimeout = setTimeout(function () { this.cantUseMessage = false; this.gameStartTimeout = null; }.bind(this), 1500);
                }
            },
            validateChoice: function (choiceObject)
            {
                if (choiceObject) {
                    let con = choiceObject.conditions;
                    if (con && con.length > 0 && this.knowledge.length === 0) return false;
                    if (con) {
                        for (let c = 0; c < con.length; c++) {
                            if (this.knowledge.indexOf(con[c]) === -1) return false;
                        }
                    }
                    return true;
                }
                return false;
            },
            validateDialogue: function (dialogueObject)
            {
                if (dialogueObject.conditional)
                {
                    let conditions = dialogueObject.conditional.conditions;
                    let valid = true;
                    for (let c = 0; c < conditions.length; c++) {
                        if (this.knowledge.indexOf(conditions[c]) === -1) {
                            valid = false;
                            break;
                        }
                    }
                    if (valid) return this.validateDialogue(dialogueObject.conditional);
                    return this.validateDialogue(dialogueObject.alternate);
                }
                return dialogueObject;
            }
        },
        computed: {
            ...mapGetters({
                game: "getActiveGame",
                desktop: "getDesktop",
            })
        }
    }
</script>
<style scoped lang="scss">
    .text-game-container {
        color: var(--font-color);
        flex-grow: 1;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        font-size: font(24);

        &.mobile
        {
            font-size: font(16);
        }

        .title-screen {
            position: relative;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            .title-text {
                position: relative;
                margin-top: 32px;
                margin-bottom: 64px;
                font-size: font(32);
                font-family: var(--header-font);
                position: relative;
                font-weight: 700;
                text-align: center;
            }

            .start-button {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                pointer-events: all;
                padding: 16px 8px;
                background-color: var(--accent-color);
                box-shadow: 2px 2px 2px var(--font-color);

                &:hover, &:active {
                    background-color: var(--header-bg);

                    span {
                        color: var(--accent-color);
                        text-shadow: none;
                    }
                }

                &:active {
                    box-shadow: none;
                }

                span {
                    position: relative;
                    pointer-events: none;
                    text-transform: uppercase;
                    text-shadow: 1px 1px var(--content-bg);
                }
            }
        }

        .game-screen {
            flex-grow: 1;
            position: relative;
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            .skip-button {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: all;
            }

            .cant-use {
                position: absolute;
                pointer-events: none;
                *{
                    pointer-events: none;
                }
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .banner {
                    position: absolute;
                    top: 128px;
                    left: 0;
                    width: 100%;
                    height: fit-content;
                    padding: 16px 0;
                    display: flex;
                    flex-direction: row;
                    justify-items: center;
                    justify-content: center;
                    align-content: center;
                    align-items: center;

                    &::before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: var(--font-color);
                        opacity: 0.75;
                    }

                    span {
                        position: relative;
                        font-size: font(32);
                        font-weight: 700;
                        color: var(--content-bg);
                        margin: 0 16px;
                        text-align: center;
                    }
                }
            }

            .information-display {
                flex-grow: 1;
                position: relative;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-items: flex-end;
                justify-content: flex-end;
                align-items: flex-start;
                align-content: flex-start;

                &.mobile {
                    flex-direction: column-reverse;
                }

                .dialogue-display {
                    position: relative;
                    height: auto;
                    width: auto;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                    pointer-events: visible;

                    .dialogue-panel {
                        position: relative;
                        width: 100%;
                        height: fit-content;
                        text-align: left;
                        white-space: pre-wrap;
                        display: flex;
                        flex-direction: column;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-items: flex-start;
                        align-content: flex-start;

                        span {
                            position: relative;
                            padding: 32px 64px;
                        }

                        &.mobile {
                            span {
                                padding: 16px 32px;
                            }
                        }
                    }
                }

                .inventory-container {
                    position: relative;
                    height: 100%;
                    width: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: center;
                    align-content: center;
                    pointer-events: visible;
                    border-left: 8px solid var(--header-bg);
                    min-width: 384px;

                    &.mobile {
                        position: relative;
                        height: fit-content;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        pointer-events: visible;
                        border-bottom: 8px solid var(--header-bg);
                        border-left: none;
                        min-width: 100%;
                    }

                    .inventory-panel {
                        position: relative;
                        width: 100%;
                        height: fit-content;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;

                        .inventory-header {
                            position: relative;
                            margin-top: 32px;
                            margin-left: 8px;
                            margin-right: 8px;
                            width: fit-content;
                            height: fit-content;
                            margin-bottom: 28px;
                            font-size: font(32);
                            font-weight: 700;
                            text-transform: uppercase;

                            &.mobile {
                                margin-top: 16px;
                                margin-bottom: 12px;
                            }
                        }

                        .inventory-display {
                            position: relative;
                            width: fit-content;
                            height: fit-content;
                            display: flex;
                            flex-direction: column;
                            justify-items: center;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            margin-bottom: 28px;

                            &.mobile {
                                margin-bottom: 12px;
                                flex-direction: row;
                                flex-wrap: wrap;
                            }

                            .inventory-button {
                                position: relative;
                                width: fit-content;
                                height: fit-content;
                                display: flex;
                                flex-direction: column;
                                justify-items: center;
                                justify-content: center;
                                align-items: center;
                                align-content: center;
                                pointer-events: all;
                                margin: 4px;
                                padding: 8px 12px;
                                background-color: var(--accent-color);
                                box-shadow: 2px 2px 2px var(--font-color);

                                &:hover, &:active {
                                    background-color: var(--header-bg);

                                    span {
                                        color: var(--accent-color);
                                        text-shadow: none;
                                    }
                                }

                                &:active {
                                    box-shadow: none;
                                }

                                span {
                                    position: relative;
                                    pointer-events: none;
                                    text-transform: uppercase;
                                    text-shadow: 1px 1px var(--content-bg);
                                }
                            }
                        }
                    }
                }
            }

            .options-display {
                position: relative;
                width: 100%;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                justify-items: center;
                align-content: center;
                align-items: center;
                border-top: 8px solid var(--header-bg);

                .options-title {
                    position: relative;
                    font-size: font(32);
                    font-weight: 700;
                    margin: 16px 8px;
                    margin-bottom: 0;
                }

                .options-arrange {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-items: center;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    margin: 28px;

                    &.mobile {
                        margin: 12px;
                    }

                    .options-button {
                        position: relative;
                        width: fit-content;
                        height: fit-content;
                        display: flex;
                        flex-direction: column;
                        justify-items: center;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        pointer-events: all;
                        margin: 4px;
                        padding: 8px 12px;
                        background-color: var(--accent-color);
                        box-shadow: 2px 2px 2px var(--font-color);

                        &:hover, &:active {
                            background-color: var(--header-bg);

                            span {
                                color: var(--accent-color);
                                text-shadow: none;
                            }
                        }

                        &:active {
                            box-shadow: none;
                        }

                        span {
                            position: relative;
                            pointer-events: none;
                            text-transform: uppercase;
                            text-shadow: 1px 1px var(--content-bg);
                        }
                    }
                }
            }
        }
    }
</style>