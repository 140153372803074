<template>
	<div class="home-container">
        <div class="home-content">
            <span class="home-header" v-html="translate('home-header')" />
            <span class="home-subheader" v-html="translate('home-subheader')" />
            <img :src="'./assets/imgs/starlene-lagrasse-headshot'+(colorScale === 1 ? '-greenscale' : '')+'.jpg'" width="2048" :style="{filter: 'grayscale(' + (colorScale === 2 ? '100%)' : '0%)'), width: desktop ? '40%' : '256px', height: 'auto', position: 'relative', 'pointer-events': 'none'}"/>
            <span class="home-body" v-html="translate('home-body')"/>
            <div class="contacts-container">
                <span v-html="translate('contact')" />
                <div class="buttons-container">
                    <a href="mailto:starlene.contact@gmail.com">
                        <div class="contact-button" id="email-button" role="button" tabindex="0" @click="email('mailto:starlene.contact@gmail.com')" v-on:keydown.enter="email('mailto:starlene.contact@gmail.com')">
                            <img :src="'./assets/imgs/envelope'+(colorScale === 1 ? '-greenscale' : '')+'.png'" />
                        </div>
                    </a>
                    <a href="https://www.linkedin.com/in/star-lagrasse">
                        <div class="contact-button" id="linkedin-button" role="button" tabindex="0" @click="linkedin('https://www.linkedin.com/in/star-lagrasse')" v-on:keydown.enter="linkedin('https://www.linkedin.com/in/star-lagrasse')">
                            <img :src="'./assets/imgs/linkedin'+(colorScale === 1 ? '-greenscale' : '')+'.png'" />
                        </div>
                    </a>
                    <a href="https://bsky.app/profile/starlenelagrasse.bsky.social">
                        <div class="contact-button" id="bluesky-button" role="button" tabindex="0" @click="bsky('https://bsky.app/profile/starlenelagrasse.bsky.social')" v-on:keydown.enter="bsky('https://bsky.app/profile/starlenelagrasse.bsky.social')">
                            <img :src="'./assets/imgs/bsky'+(colorScale === 1 ? '-greenscale' : '')+'.png'" />
                        </div>
                    </a>
                    <a href="https://ideas.lego.com/profile/74f67c70-8ceb-4f4e-a2c2-6bb5936990e1">
                        <div class="contact-button" id="lego-button" role="button" tabindex="0" @click="openURL('https://ideas.lego.com/profile/74f67c70-8ceb-4f4e-a2c2-6bb5936990e1')" v-on:keydown.enter="openURL('https://ideas.lego.com/profile/74f67c70-8ceb-4f4e-a2c2-6bb5936990e1')">
                            <img :src="'./assets/imgs/lego'+(colorScale === 1 ? '-greenscale' : '')+'.png'" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
		name: 'HomePage',
		props: {
        },
        methods:{
            email: function(url){
                this.dispatchAchievement("contact");
                this.openURL(url);
            },
            linkedin: function (url) {
                this.dispatchAchievement("linkedin");
                this.openURL(url);
            },
            bsky: function (url) {
                this.dispatchAchievement("bsky");
                this.openURL(url);
            }
        },
        computed: {
            ...mapGetters({
                colorScale: "getColorScale",
                desktop: "getDesktop"
            })
        }
    }
</script>

<style scoped lang="scss">
    .home-container {
        color: var(--font-color);
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        text-align: left;

        .home-content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            padding: 8px 10%;
            width: fit-content;
            height: fit-content;
        }

        .home-header {
            width: fit-content;
            height: fit-content;
            font-size: font(32);
            font-family: var(--header-font);
            position: relative;
            font-weight: 700;
            text-align: center;
        }

        .home-subheader {
            width: fit-content;
            height: fit-content;
            font-size: font(16);
            font-family: var(--header-font);
            position: relative;
            font-weight: 400;
            text-align: center;
            margin-bottom: 16px;
            font-style: italic;
        }

        .home-body {
            width: fit-content;
            height: fit-content;
            margin-top: 32px;
            position: relative;
            font-family: var(--body-font);
            font-size: font(24);
            font-weight: 400;
            align-self: flex-start;
        }

        .contacts-container {
            position: relative;
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            justify-content: center;
            align-content: center;
            width: fit-content;
            height: fit-content;
            margin-bottom: 24px;

            span {
                position: relative;
                font-size: font(24);
                text-transform: uppercase;
            }

            .buttons-container {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-items: center;
                align-items: center;
                justify-content: center;
                align-content: center;
                width: fit-content;
                height: fit-content;
                flex-wrap: wrap;

                .contact-button {
                    position: relative;
                    pointer-events: all;
                    width: font(64);
                    height: font(64);
                    margin: 4px 8px;
                    border-radius: 50%;
                    background-color: var(--header-bg);
                    filter: drop-shadow(2px 2px 2px var(--font-color));
                    display: flex;
                    flex-direction: row;
                    justify-items: center;
                    align-items: center;
                    justify-content: center;
                    align-content: center;

                    &:first-of-type {
                        margin-left: 16px;
                    }

                    &:last-of-type {
                        margin-right: 0
                    }

                    &:active, &:hover {
                        background-color: var(--accent-color);
                    }

                    &:active {
                        filter: drop-shadow(1px 1px 1px var(--font-color));
                    }

                    * {
                        position: relative;
                        pointer-events: none;
                    }

                    img {
                        width: 60%;
                        height: auto;
                    }
                }
            }
        }
    }
</style>