import { createApp } from 'vue';
import App from './App.vue';
import store from './store.js';

import { initTranslation } from "./js/translate.js";
import { mixins } from "./js/mixins.js";
import { checkCompatibility, readCookieAtStart } from "./js/compatibility.js";

let init = async function () {
    await initTranslation();
    checkCompatibility();
    let cookie = readCookieAtStart("optionsData");
    let achievements = readCookieAtStart("cheevos");

    let query = new URLSearchParams(window.location.search);
    if (query.get("page")) {
        switch (query.get("page")) {
            default: case 0: case 1: case "about": case "bio": case "home":
                store.commit("setCurrentPage", 1);
                break;
            case 2: case "resume": case "history": case "employment": case "jobs": case "career":
                store.commit("setCurrentPage", 2);
                break;
            case 3: case "projects": case "work": case "portfolio":
                store.commit("setCurrentPage", 3);
                break;
            case 4: case "skills": case "education": case "skills-and-education": case "education-and-skills": case "skills-education": case "education-skills": case "ed": case "ed-skills": case "ed-and-skills": case "skills-ed": case "skills-and-ed": case "knowledge":
                store.commit("setCurrentPage", 4);
                break;
            case 5: case "organizations": case "orgs": case "affiliations": case "orgs-and-affiliations": case "organizations-and-affiliations": case "orgs-affiliations": case "organizations-affiliations": case "affiliations-organizations": case "affiliations-orgs": case "affiliations-and-organizations": case "affiliations-and-orgs": case "affls-orgs": case "affls-and-orgs": case "affls-organizations": case "affls-and-organizations": case "orgs-affls": case "orgs-and-affls": case "organizations-affls": case "organizations-and-affls":
                store.commit("setCurrentPage", 5);
                break;
            case 6: case "game":
                store.commit("setCurrentPage", 6);
                break;
            case 7: case "achievements":
                store.commit("setCurrentPage", 7);
                break;
            case "seeker":
                store.commit("setActiveGame", "seeker");
                store.commit("setCurrentPage", 6);
                break;
            case 8: case "blackjack": case "poker": case "twenty-one": case "twentyone":
                store.commit("setCurrentPage", 8)
                break;
            case 9: case "extras": case "secrets": case "login": case "bonus":
                store.commit("setCurrentPage", 9);
                break;
            case 10: case "options": case "config": case "configuration": case "menu": case "font":
                store.commit("setCurrentPage", 10);
                break;
        }
    }
    else
    {
        store.commit("setCurrentPage", 1);
    }

    createApp({ extends: App })
        .mixin(mixins)
        .use(store).mount('#app');

    let root = document.querySelector(":root");
    if (cookie && root) {
        let jsonCookie = JSON.parse(cookie);
        if (jsonCookie.colorScale) {
            store.commit("setColorScale", jsonCookie.colorScale);
            root.style.setProperty("--header-bg", jsonCookie.colorScale === 2 ? 'grey' : jsonCookie.colorScale === 1 ? '#306230' : '#b660cd');
            root.style.setProperty("--content-bg", jsonCookie.colorScale === 2 ? 'white' : jsonCookie.colorScale === 1 ? '#9bbc0f' : '#d7bfdc');
            root.style.setProperty("--accent-color", jsonCookie.colorScale === 2 ? 'lightgrey' : jsonCookie.colorScale === 1 ? '#8bac0f' : '#ffc055');
            root.style.setProperty("--font-color", jsonCookie.colorScale === 1 ? '#0f380f' : 'black');
        }
        if (jsonCookie.fontFamily) {
            store.commit("setFont", jsonCookie.fontFamily);
            root.style.setProperty("--header-font", jsonCookie.fontFamily === 2 ? "'Roboto Mono', monospace" : jsonCookie.fontFamily === 1 ? "MatrixSansScreen, sans-serif" : "PlayerSans, sans-serif");
            root.style.setProperty("--body-font", jsonCookie.fontFamily === 2 ? "'Roboto Mono', monospace" : jsonCookie.fontFamily === 1 ? "MatrixSansScreen, sans-serif" : "MatrixSansVideo, sans-serif");
        }
        store.commit("setScanlines", jsonCookie.scanlines !== null && jsonCookie.scanlines !== undefined ? jsonCookie.scanlines : true);
        root.style.setProperty("--font-scale", (jsonCookie.fontScale ? jsonCookie.fontScale : 1) + 'px');
    }
    else {
        if (!store.getters.getDesktop) {
            store.commit("setFont", 1);
            root.style.setProperty("--header-font", "MatrixSansScreen, sans-serif");
            root.style.setProperty("--body-font", "MatrixSansScreen, sans-serif");
        }
    }

    if (achievements && root) {
        let achievementsJSON = JSON.parse(achievements)
        if (Object.keys(achievementsJSON).length > 0) store.commit("setAchievementsOn", true);
        store.commit("setAcquiredAchievements", { ...achievementsJSON });
    }
};
init();
