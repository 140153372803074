<template>
    <div class="blackjack-container">
        <TransitionerObject name="fade-and-blur-normal">
            <div class="title-screen" v-if="gameState === 1">
                <span class="title-text" v-html="translate('blackjack-title')" />
                <div class="hand-container" style="margin-top:16px;">
                    <div v-if="deck.length > 0" class="card" :class="{'red-suit': deck[deckIndex].class==='DIAMOND' || deck[deckIndex].class === 'HEART', 'black-suit': deck[deckIndex].class==='CLUB' || deck[deckIndex].class === 'SPADE', 'main': colorScale === 0, 'green-scale': colorScale === 1, 'grey-scale': colorScale === 2, 'trans': colorScale === 3, 'shadow': colorScale === '4'}">
                        <span v-html="deck[deckIndex].marker" />
                        <img :src="'./assets/imgs/'+deck[deckIndex].symbol+'.svg'" />
                    </div>
                    <div v-if="deck.length > 0" class="card" :class="{'red-suit': deck[(deckIndex + 16) % deck.length].class==='DIAMOND' || deck[(deckIndex + 16) % deck.length].class === 'HEART', 'black-suit': deck[(deckIndex + 16) % deck.length].class==='CLUB' || deck[(deckIndex + 16) % deck.length].class === 'SPADE', 'main': colorScale === 0, 'green-scale': colorScale === 1, 'grey-scale': colorScale === 2, 'trans': colorScale === 3, 'shadow': colorScale === '4'}">
                        <span v-html="deck[(deckIndex + 16) % deck.length].marker" />
                        <img :src="'./assets/imgs/'+deck[(deckIndex + 16) % deck.length].symbol+'.svg'" />
                    </div>
                </div>
                <div class="start-button" tabindex="0" role="button" @click="beginGame" v-on:keydown.enter="beginGame">
                    <span v-html="translate('game-start')" />
                </div>
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-and-blur-normal">
            <div class="game-screen" v-if="gameState > 1">
                <span class="hand-label" v-html="translate('dealer-hand')" />
                <div class="hand-container">
                    <div v-for="(card, c) in dealerHand" class="card" :class="{'hidden': c === 0 && gameState < 5, 'red-suit': !(c === 0 && gameState < 5) && (card.class==='DIAMOND' || card.class === 'HEART'), 'black-suit': !(c === 0 && gameState < 5) && (card.class==='CLUB' || card.class === 'SPADE'), 'main': colorScale === 0, 'green-scale': colorScale === 1, 'grey-scale': colorScale === 2, 'trans': colorScale === 3, 'shadow': colorScale === '4'}" :key="'dealer-card-'+c">
                        <span v-if="!(c === 0 && gameState < 5)" v-html="card.marker" />
                        <img v-if="!(c === 0 && gameState < 5)" :src="'./assets/imgs/'+card.symbol+'.svg'" />
                    </div>
                </div>
                <div class="deck-container">
                    <div class="card hidden">
                        <div v-if="dealing" :class="dealInPlayer ? 'down-card': 'up-card'" />
                    </div>
                </div>
                <span class="hand-label" v-html="translate('player-hand')" />
                <div class="hand-container">
                    <div v-for="(card, c) in playerHand" class="card" :class="{'red-suit': card.class==='DIAMOND' || card.class === 'HEART', 'black-suit': card.class==='CLUB' || card.class === 'SPADE', 'main': colorScale === 0, 'green-scale': colorScale === 1, 'grey-scale': colorScale === 2, 'trans': colorScale === 3, 'shadow': colorScale === '4'}" :key="'player-card-'+c">
                        <span v-html="card.marker" />
                        <img :src="'./assets/imgs/'+card.symbol+'.svg'" />
                    </div>
                </div>
                <span v-if="gameState=== 3" class="choice-label" v-html="translate('game-choices')" />
                <span v-if="gameState=== 6" class="choice-label" v-html="victoryCondition === -1 ? translate('blackjack-player-bust') : victoryCondition === -3 ? translate('blackjack-dealer-bust') : victoryCondition === 2 ? translate('blackjack-tie') : victoryCondition == 3 ? translate('blackjack-dealer-win'): translate('blackjack-player-win')" />
                <div v-if="gameState === 3 || gameState === 6" class="choices-container">
                    <div v-if="gameState === 3" class="choice-button" @click="playerHit" v-on:keydown.enter="playerHit">
                        <span v-html="translate('blackjack-hit')" />
                    </div>
                    <div v-if="gameState === 3" class="choice-button" @click="playerStand" v-on:keydown.enter="playerStand">
                        <span v-html="translate('blackjack-stand')" />
                    </div>
                    <div v-if="gameState === 6" class="choice-button" @click="cleanup" v-on:keydown.enter="cleanup">
                        <span v-html="translate('blackjack-replay')" />
                    </div>
                </div>
            </div>
        </TransitionerObject>
    </div>
</template>
<script>
    import TransitionerObject from './TransitionerObject.vue';
    import { mapGetters } from 'vuex'
    export default {
        components:
        {
            TransitionerObject
        },
        data: function ()
        {
            return {
                suits: [
                    {
                        "class": "DIAMOND",
                        "symbol": "diamonds"
                    },
                    {
                        "class": "CLUB",
                        "symbol": "clubs"
                    },
                    {
                        "class": "HEART",
                        "symbol": "hearts"
                    },
                    {
                        "class": "SPADE",
                        "symbol": "spades"
                    }
                ],
                numbers: [
                    {
                        "number": "TWO",
                        "value": 2,
                        "marker": "2"
                    },
                    {
                        "number": "THREE",
                        "value": 3,
                        "marker": "3"
                    },
                    {
                        "number": "FOUR",
                        "value": 4,
                        "marker": "4"
                    },
                    {
                        "number": "FIVE",
                        "value": 5,
                        "marker": "5"
                    },
                    {
                        "number": "SIX",
                        "value": 6,
                        "marker": "6"
                    },
                    {
                        "number": "SEVEN",
                        "value": 7,
                        "marker": "7"
                    },
                    {
                        "number": "EIGHT",
                        "value": 8,
                        "marker": "8"
                    },
                    {
                        "number": "NINE",
                        "value": 9,
                        "marker": "9"
                    },
                    {
                        "number": "TEN",
                        "value": 10,
                        "marker": "10"
                    },
                    {
                        "number": "JACK",
                        "value": 10,
                        "marker": "J"
                    },
                    {
                        "number": "QUEEN",
                        "value": 10,
                        "marker": "Q"
                    },
                    {
                        "number": "KING",
                        "value": 10,
                        "marker": "K"
                    },
                    {
                        "number": "ACE",
                        "value": 11,
                        "marker": "A"
                    }
                ],
                pSoftScore: 0,
                softScore: 0,
                deck: [],
                playerHand: [],
                dealerHand: [],
                gameState: 1,
                gameTimeout: null,
                dealInPlayer: true,
                dealing: false,
                victoryCondition: 0,
                cheevosTimeout: null,
                deckIndex: 0,
                deckInterval: null
            }
        },
        mounted: async function ()
        {
            for (let s = 0; s < this.suits.length; s++)
            {
                for (let n = 0; n < this.numbers.length; n++) {
                    let card = { ...this.suits[s], ...this.numbers[n] };
                    this.deck.push({...card});
                }
            }
            this.deck = this.shuffle([...this.deck], 30);
            this.cheevosTimeout = setTimeout(function () { this.dispatchAchievement("gamer-moment"); }.bind(this), 500);
            this.deckInterval = setInterval(function () { if (this.gameState !== 1) return; this.deckIndex++; this.deckIndex = this.deckIndex % this.deck.length; }.bind(this), 1500);
        },
        unmounted: async function ()
        {
            if (this.gameTimeout) clearTimeout(this.gameTimeout);
            if (this.cheevosTimeout) clearTimeout(this.cheevosTimeout);
            if (this.deckInterval) clearInterval(this.deckInterval);
        },
        methods: {
            beginGame: function()
            {
                this.gameState = 0;
                this.pSoftScore = 0;
                this.gameTimeout = setTimeout(function () { this.deck = this.shuffle([...this.deck]); this.dealInPlayer = true; this.gameState = 2; this.gameTimeout = setTimeout(this.dealIn.bind(this), 500); }.bind(this), 500);
            },
            dealIn: function()
            {
                if (this.playerHand.length === 2 && this.dealerHand.length === 2)
                {
                    this.gameTimeout = null;
                    let openingState = this.checkForNaturals();
                    switch(openingState)
                    {
                        default:
                            this.gameState = 3;
                            break;
                        case 1:
                            this.dispatchAchievement("big-naturals","blackjack");
                            this.gameTimeout = setTimeout(this.evaluateGame.bind(this), 500);
                            break;
                        case 2:
                            this.dispatchAchievement("samesies", "blackjack");
                            this.gameTimeout = setTimeout(this.evaluateGame.bind(this), 500);
                            break;
                    }
                    return;
                }
                this.dealing = true;
                this.gameTimeout = setTimeout(function(){
                    this.dealing = false;
                    if (this.dealInPlayer) this.playerHand.push(this.deck.shift());
                    else this.dealerHand.push(this.deck.shift());
                    this.dealInPlayer = !this.dealInPlayer;
                    this.gameTimeout = setTimeout(this.dealIn.bind(this), 500);
                }.bind(this), 500)
            },
            playerHit: function()
            {
                this.gameState = 4;
                this.dealInPlayer = true;
                this.dealing = true;
                this.gameTimeout = setTimeout(function(){
                    this.pSoftScore = this.softScore;
                    this.dealing = false;
                    this.playerHand.push(this.deck.shift());
                    let value = this.getHandValue(this.playerHand);
                    if (value > 21)
                    {
                        this.dispatchAchievement("bust","blackjack");
                        this.gameTimeout = setTimeout(this.evaluateGame.bind(this), 500);
                    }
                    else if (value === 21)
                    {
                        this.playerStand();
                        return;
                    }
                    else this.gameState = 3;
                    this.gameTimeout = null;
                }.bind(this), 500);
            },
            playerStand: function(){
                this.gameState = 5;
                this.gameTimeout = setTimeout(this.dealerLogic.bind(this), 500);
            },
            dealerLogic: function(){
                let dealerValue = this.getHandValue(this.dealerHand, false);
                if (dealerValue < 17 && dealerValue <= this.getHandValue(this.playerHand, false))
                {
                    this.dealInPlayer = false;
                    this.dealing = true;
                    this.gameTimeout = setTimeout(function(){
                        this.dealing = false;
                        this.dealerHand.push(this.deck.shift());
                        this.gameTimeout = setTimeout(this.dealerLogic.bind(this), 500);
                    }.bind(this), 500);
                    return;
                }
                this.gameTimeout = setTimeout(this.evaluateGame.bind(this), 500);
            },
            shuffle: function (indeck, turns = 0)
            {
                let outdeck = [...indeck];
                if (turns <= 0) turns = 5 + Math.floor(Math.random() * 11);
                for (let t = 0; t < turns; t++)
                {
                    let method = Math.random();
                    if (method < 0.25)
                    {
                        outdeck = this.riffleShuffle([...outdeck]);
                        continue;
                    }
                    if (method < 0.50)
                    {
                        outdeck = this.overhandShuffle([...outdeck]);
                        continue;
                    }
                    if (method < 0.75)
                    {
                        outdeck = this.mongeanShuffle([...outdeck]);
                        continue;
                    }
                    outdeck = this.pileShuffle([...outdeck]);
                }
                return [...outdeck];
            },
            riffleShuffle: function (indeck)
            {
                let outdeck = [];
                let perfect = Math.random() >= 0.95;
                let trueHalf = Math.floor(indeck.length / 2);
                let half = perfect ? trueHalf : (Math.floor(trueHalf * (1 + Math.random())) - Math.floor(trueHalf / 2));
                let left = indeck.slice(0, half);
                let right = indeck.slice(half);
                let doLeft = Math.random() <= 0.5;
                while (left.length > 0 || right.length > 0)
                {
                    if (left.length === 0)
                    {
                        outdeck.unshift(right.pop());
                        continue;
                    }
                    if (right.length === 0)
                    {
                        outdeck.unshift(left.pop());
                        continue;
                    }
                    let drop = perfect ? 1 : (Math.floor(Math.random() * 10) + 1);
                    drop = Math.min(drop, doLeft ? left.length : right.length);
                    for (let d = 0; d < drop; d++) outdeck.unshift(doLeft ? left.pop() : right.pop());
                    doLeft = !doLeft;
                }
                return [...outdeck];
            },
            overhandShuffle: function (indeck)
            {
                if (Math.random() >= 0.5) indeck = this.cutShuffle([...indeck]);
                let doTop = Math.random() <= 0.5;
                let offTop = Math.random() <= 0.5;
                let drop = Math.floor(Math.random() * 16) + 5;
                let outdeck = [];
                while (indeck.length > 0)
                {
                    let reserve = offTop ? indeck.slice(0, drop) : indeck.slice(indeck.length - drop);
                    indeck.splice(offTop ? 0 : indeck.length - drop, drop);
                    if (outdeck.length === 0) outdeck = [...reserve];
                    else outdeck = doTop ? reserve.concat(outdeck) : outdeck.concat(reserve);
                    drop = Math.floor(Math.random() * 16) + 5;
                    drop = Math.min(indeck.length, drop);
                    offTop = Math.random() <= 0.5;
                    doTop = !doTop;
                }
                return [...outdeck];
            },
            mongeanShuffle: function (indeck)
            {
                if (Math.random() >= 0.5) indeck = this.cutShuffle([...indeck]);
                let outdeck = [];
                let doTop = Math.random() <= 0.5;
                while (indeck.length > 0)
                {
                    if (doTop) outdeck.unshift(indeck.shift());
                    else outdeck.push(indeck.shift());
                    doTop = !doTop;
                }
                return [...outdeck];
            },
            pileShuffle: function (indeck)
            {
                if (Math.random() >= 0.5) indeck = this.cutShuffle([...indeck]);
                let outdeck = [];
                let piles = [];
                let numPiles = 3 + Math.floor(Math.random() * 4);
                for (let p = 0; p < numPiles; p++) piles.push([]);
                let pileIndex = 0;
                while (indeck.length > 0)
                {
                    piles[pileIndex].push(indeck.shift());
                    pileIndex++;
                    pileIndex = pileIndex % numPiles;
                }
                while (piles.length > 0)
                {
                    if (piles.length === 1)
                    {
                        outdeck = outdeck.concat(piles[0]);
                        piles = [];
                        continue;
                    }
                    pileIndex = Math.floor(Math.random() * piles.length);
                    if (outdeck.length === 0) outdeck = [...piles[pileIndex]];
                    else outdeck = outdeck.concat(piles[pileIndex]);
                    piles.splice(pileIndex, 1);
                }
                return [...outdeck];
            },
            cutShuffle: function (indeck)
            {
                let outdeck = [];
                let perfect = Math.random() >= 0.95;
                let trueHalf = Math.floor(indeck.length / 2);
                let half = perfect ? trueHalf : (Math.floor(trueHalf * (1 + Math.random())) - Math.floor(trueHalf / 2));
                let top = indeck.slice(0, half);
                let bottom = indeck.slice(half);
                outdeck = bottom.concat(top);
                return [...outdeck];
            },
            printDeck: function ()
            {
                console.log("----- BEGIN DECK PRINT -----");
                for (let c = 0; c < this.deck.length; c++) console.log(this.deck[c].number, "of", this.deck[c].class + "s");
                console.log("----- END DECK PRINT -----");
            },
            getHandValue: function (hand, isPlayer = true)
            {
                let val = 0;
                let aces = 0;
                for (let c = 0; c < hand.length; c++)
                {
                    val += hand[c].value;
                    if (hand[c].number === "ACE") aces++;
                }
                if (isPlayer)
                {
                    this.softScore = val;
                }
                if (val > 21)
                {
                    for (let a = 0; a < aces; a++)
                    {
                        val -= 10;
                        if (val <= 21) break;
                    }
                }
                if (isPlayer) for (let b = 0; b < aces; b++) this.softScore -= 10; 
                return val;
            },
            checkForNaturals: function ()
            {
                let pHand = this.getHandValue(this.playerHand);
                if (pHand === 21)
                {
                    this.gameState = 5;
                    let dHand = this.getHandValue(this.dealerHand, false);
                    if (dHand === 21) return 2; //everyone is a natural
                    return 1; //only player natural
                }
                return 0; //no naturals
            },
            evaluateGame: function()
            {
                let pHand = this.getHandValue(this.playerHand);
                let dHand = this.getHandValue(this.dealerHand, false);
                this.victoryCondition = 0;
                if (pHand > 21) this.victoryCondition = -1;
                else if (pHand === 21 && dHand !== 21)
                {
                    this.victoryCondition = 1;
                    this.dispatchAchievement("champion", "blackjack");
                    if (this.pSoftScore >= 17) this.dispatchAchievement("psychic", "blackjack");
                    if (dHand > 21)
                    { 
                        this.dispatchAchievement("absolute-victory", "blackjack");
                        this.dispatchAchievement("crushing-victory", "blackjack");
                    }
                }
                else if (dHand > 21)
                {
                    this.victoryCondition = -3;
                    this.dispatchAchievement("champion", "blackjack");
                    this.dispatchAchievement("absolute-victory", "blackjack");
                }
                else if (pHand > dHand)
                {
                    this.victoryCondition = 1;
                    this.dispatchAchievement("champion", "blackjack");
                }
                else if (pHand === dHand) this.victoryCondition = 2;
                else this.victoryCondition = 3
                this.gameState = 6;
            },
            cleanup: function()
            {
                this.deck = this.deck.concat(this.playerHand);
                this.deck = this.deck.concat(this.dealerHand);
                this.playerHand = [];
                this.dealerHand = [];
                this.gameState = 0;
                this.gameTimeout = setTimeout(this.beginGame.bind(this), 1000);
            }
        },
        computed:
        {
            ...mapGetters({
                colorScale: 'getColorScale', 
            })
        }
    }
</script>
<style scoped lang="scss">
    .blackjack-container {
        color: var(--font-color);
        flex-grow: 1;
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        font-size: font(24);

        .title-screen {
            position: relative;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            .title-text {
                position: relative;
                margin-top: 32px;
                font-size: font(32);
                font-family: var(--header-font);
                position: relative;
                font-weight: 700;
                text-align: center;
            }

            .start-button {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                pointer-events: all;
                padding: 16px 8px;
                background-color: var(--accent-color);
                box-shadow: 2px 2px 2px var(--font-color);
                margin-top: 64px;

                &:hover, &:active {
                    background-color: var(--header-bg);

                    span {
                        color: var(--accent-color);
                        text-shadow: none;
                    }
                }

                &:active {
                    box-shadow: none;
                }

                span {
                    position: relative;
                    pointer-events: none;
                    text-transform: uppercase;
                    text-shadow: 1px 1px var(--content-bg);
                }
            }
        }

        .hand-container {
            position: relative;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: row;
            justify-items: center;
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            min-height: 136px;
        }

        .card {
            position: relative;
            width: 96px;
            height: 128px;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: var(--content-bg);
            display: flex;
            flex-direction: column;
            justify-items: center;
            justify-content: center;
            align-items: center;
            align-content: center;
            color: var(--header-bg);
            filter: drop-shadow(4px 4px 2px var(--font-color));
            border: solid 4px var(--accent-color);
            margin: 4px;

            &.hidden {
                background-color: var(--header-bg);
            }

            span {
                position: relative;
                margin-bottom: 4px;
                text-shadow: 2px 2px 1px var(--font-color);
            }

            &.red-suit {
                color: var(--accent-color);

                &.main {
                    img {
                        filter: invert(80%) sepia(10%) saturate(4270%) hue-rotate(324deg) brightness(119%) contrast(102%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.green-scale {
                    color: var(--header-bg);

                    img {
                        filter: invert(25%) sepia(9%) saturate(3912%) hue-rotate(71deg) brightness(101%) contrast(72%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.grey-scale {
                    color: var(--header-bg);

                    img {
                        filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(183deg) brightness(94%) contrast(86%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.trans {
                    img {
                        filter: invert(82%) sepia(17%) saturate(4288%) hue-rotate(166deg) brightness(99%) contrast(98%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.shadow {
                    color: var(--header-bg);

                    img {
                        filter: invert(37%) sepia(93%) saturate(6769%) hue-rotate(350deg) brightness(98%) contrast(100%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }
            }

            &.black-suit {

                &.main {
                    img {
                        filter: invert(54%) sepia(61%) saturate(2149%) hue-rotate(239deg) brightness(84%) contrast(90%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.green-scale {
                    color: var(--font-color);

                    img {
                        filter: invert(13%) sepia(11%) saturate(5098%) hue-rotate(78deg) brightness(105%) contrast(92%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.grey-scale {
                    color: var(--font-color);

                    img {
                        filter: drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.trans {
                    color: var(--font-color);

                    img {
                        filter: invert(14%) sepia(56%) saturate(6139%) hue-rotate(321deg) brightness(96%) contrast(110%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }

                &.shadow {
                    color: var(--accent-color);

                    img {
                        filter: invert(84%) sepia(27%) saturate(2969%) hue-rotate(322deg) brightness(103%) contrast(94%) drop-shadow(2px 2px 1px var(--font-color));
                    }
                }
            }

            img {
                position: relative;
                width: 48px;
                height: 48px;
            }
        }

        .game-screen {
            position: relative;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            margin-top: 16px;

            .hand-label {
                position: relative;
                text-transform: uppercase;
                text-decoration: underline;
                margin-bottom: 8px;
            }

            .choice-label {
                position: relative;
                margin: 8px 0;
            }

            .deck-container {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin: 128px 0;
            }

            .choices-container {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: row;
                justify-items: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                flex-wrap: wrap;

                .choice-button {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-items: center;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    pointer-events: all;
                    padding: 16px 8px;
                    background-color: var(--accent-color);
                    box-shadow: 2px 2px 2px var(--font-color);
                    margin: 4px 8px;

                    &:hover, &:active {
                        background-color: var(--header-bg);

                        span {
                            color: var(--accent-color);
                            text-shadow: none;
                        }
                    }

                    &:active {
                        box-shadow: none;
                    }

                    span {
                        position: relative;
                        pointer-events: none;
                        text-transform: uppercase;
                        text-shadow: 1px 1px var(--content-bg);
                    }
                }
            }
        }

        .down-card, .up-card {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 96px;
            height: 128px;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: var(--header-bg);
            display: flex;
            flex-direction: column;
            justify-items: center;
            justify-content: center;
            align-items: center;
            align-content: center;
            filter: drop-shadow(4px 4px 2px var(--font-color));
            border: solid 4px var(--accent-color);
        }

        .up-card {
            bottom: 256px;
            animation: linear 0.3s card-up;
            animation-iteration-count: 1;
        }

        @keyframes card-up {
            0% {
                bottom: 0;
            }

            100% {
                bottom: 256px;
            }
        }

        .down-card {
            top: 256px;
            animation: linear 0.3s card-down;
            animation-iteration-count: 1;
        }

        @keyframes card-down {
            0% {
                top: 0;
            }

            100% {
                top: 256px;
            }
        }
    }
</style>