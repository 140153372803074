<template>
    <div class="extras-container">
        <div class="extras-content" v-if="state === 0 ">
            <span class="extras-title" v-html="translate('extras-title')" />
            <div class="extras-entry">
                <span class="entry-title" v-html="translate('user-entry')" />
                <input type="text" class="entry-input" size="32" id="username" tabindex="0" />
            </div>
            <div class="extras-entry">
                <span class="entry-title" v-html="translate('pass-entry')" />
                <input type="password" class="entry-input" size="32" id="password" tabindex="0" v-on:keydown.enter="checkCredentials"/>
            </div>
            <div class="entry-button" id="entry-button" @click="checkCredentials" tabindex="0" role="button" v-on:keydown.enter="checkCredentials">
                <span v-html="translate('extras-entry-button')" />
            </div>
        </div>
        <TransitionerObject name="fade-normal">
            <div class="extras-content" v-if="state === 1">
                <span class="extras-title" v-html="loadingMessage" />
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-normal">
            <div class="extras-content" v-if="state === 2">
                <span class="extras-title" v-html="loadingMessage" />
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-normal">
            <div class="extras-content" v-if="state === 3" :style="{width: '100%', 'padding-left': 0, 'padding-right': 0, 'overflow-x': 'clip'}">
                <div class="drooping-bars-container">
                    <div class="bar-container" v-for="(bar, b) in effectsData['drooping-bars']" :key="'droopingbar'+b">
                        <div class="bar" :style="{height: bar.height + '%'}" />
                    </div>
                </div>
                <div class="rotating-disc">
                    <div class="disc-markings" :style="{width: '240px', height: '240px', transform: 'translate(-50%, -50%) rotate('+effectsData['disc-rotations'][0]+'deg)'}" />
                    <div class="disc-markings" :style="{width: '208px', height: '208px', transform: 'translate(-50%, -50%) rotate('+effectsData['disc-rotations'][1]+'deg)'}" />
                    <div class="disc-markings" :style="{width: '176px', height: '176px', transform: 'translate(-50%, -50%) rotate('+effectsData['disc-rotations'][2]+'deg)'}" />
                    <div class="disc-markings" :style="{width: '144px', height: '144px', transform: 'translate(-50%, -50%) rotate('+effectsData['disc-rotations'][3]+'deg)'}" />
                    <div class="disc-markings" :style="{width: '112px', height: '112px', transform: 'translate(-50%, -50%) rotate('+effectsData['disc-rotations'][4]+'deg)'}" />
                </div>
                <div class="loading-bars-container">
                    <div class="loading-section-container" v-for="(load, l) in effectsData['loading-bars']" :key="'loadingbar'+l">
                        <span v-html="load.messages[load.index]" />
                        <div class="loading-bar-container">
                            <div class="loading-bar" :style="{width: load.width + '%'}" />
                        </div>
                    </div>
                </div>
                <div class="rotating-florp" />
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-normal">
            <div class="extras-content" v-if="state === 4" :style="{width: '100%', 'padding-left': 0, 'padding-right': 0}">
                <video playsinline controls controlsList="nodownload" :src="'./assets/vid/video-cover-letter-sega.mp4'" preload="auto" :style="{filter: 'grayscale(' + (colorScale === 2 ? '100%)' : '0%)')}"></video>
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-normal">
            <div class="extras-content" v-if="state === 5" :style="{'align-items': 'flex-start', 'align-content':'flex-start', 'text-align':'left'}">
                <span class="extras-title" v-html="recipe.title" />
                <div class="recipe-container">
                    <div class="ingredients-container">
                        <span v-for="ingredient in recipe.ingredients" :key="ingredient" v-html="ingredient" />
                    </div>
                </div>
                <ol class="steps-container">
                    <li v-for="instruction in recipe.instructions" v-html="instruction" :key="instruction" />
                </ol>
                <span class="extras-title" v-html="translate('recipe-variations')" :style="{'margin-bottom': '8px'}" v-if="recipe.variations && recipe.variations !== ''" />
                <ul class="variations-container" v-if="recipe.variations && recipe.variations !== ''">
                    <li v-for="variation in recipe.variations" v-html="variation" :key="variation" />
                </ul>
                <span class="extras-title" v-html="translate('recipe-notes')" :style="{'margin-bottom': '8px'}" v-if="recipe.notes && recipe.notes !== ''" />
                <ul class="variations-container" v-if="recipe.notes && recipe.notes !== ''">
                    <li v-for="note in recipe.notes" v-html="note" :key="note" />
                </ul>
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-normal">
            <div class="extras-content" v-if="state === 6" :style="{width: '100%', 'padding-left': 0, 'padding-right': 0}">
                <video playsinline controls controlsList="nodownload" :src="'./assets/vid/video-cover-letter-pokemon.mp4'" preload="auto" :style="{filter: 'grayscale(' + (colorScale === 2 ? '100%)' : '0%)')}"></video>
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-and-blur-normal">
            <div class="extras-content" v-if="state === 8" :style="{width: '100%', 'padding-left': 0, 'padding-right': 0}">
                <img :src="'./assets/imgs/magic-8bt'+(colorScale === 1 ? '-greenscale' : '')+'.png'" width="2048" :style="{width: desktop ? '25%' : '256px', height: 'auto', position: 'relative', 'pointer-events': 'none'}" />
                <span class="extras-title" v-html="translate('magic-title')" />

                <!-- 8-Ball Entry -->
                <TransitionerObject name="fade-front-half">
                    <span class="extras-subtitle" v-if="magic8State === 0" v-html="translate('magic-entry')" />
                </TransitionerObject>
                <TransitionerObject name="fade-front-half">
                    <div class="extras-entry" v-if="magic8State === 0">
                        <input type="text" class="entry-input" size="32" id="question" tabindex="0" v-on:keydown.enter="checkMagic8Question" />
                    </div>
                </TransitionerObject>
                <TransitionerObject name="fade-front-half">
                    <div class="entry-button" v-if="magic8State === 0" id="magic-entry-button" @click="checkMagic8Question" v-on:keydown.enter="checkMagic8Question" tabindex="0" role="button">
                        <span v-html="translate('extras-entry-button')" />
                    </div>
                </TransitionerObject>
                <TransitionerObject name="fade-front-half">
                    <span class="extras-disclaimer" v-if="magic8State === 0" v-html="translate('magic-disclaimer')" />
                </TransitionerObject>

                <!-- 8-Ball Loading -->
                <TransitionerObject name="fade-front-half">
                    <span class="extras-title" v-if="magic8State === 1" v-html="magic8Loading[magic8LoadIndex]" />
                </TransitionerObject>

                <!-- 8-Ball Results -->
                <TransitionerObject name="fade-front-half">
                    <span class="extras-body" v-if="magic8State === 2" v-html="magic8Query" />
                </TransitionerObject>
                <TransitionerObject name="fade-front-half">
                    <span class="extras-subtitle" v-if="magic8State === 2" v-html="magic8Responses[magic8ResponseIndex]" />
                </TransitionerObject>
                <TransitionerObject name="fade-front-half">
                    <div class="entry-button" v-if="magic8State === 2" id="magic-reload-button" @click="startMagic8" v-on:keydown.enter="startMagic8" tabindex="0" role="button">
                        <span v-html="translate('magic-reload-button')" />
                    </div>
                </TransitionerObject>
            </div>
        </TransitionerObject>
        <TransitionerObject name="fade-normal">
            <div class="extras-content" v-if="state === 100">
                <span class="extras-title" v-html="loadingMessage" />
            </div>
        </TransitionerObject>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import TransitionerObject from "./TransitionerObject.vue"
    export default
        {
            components: {
                TransitionerObject
            },
            mounted: async function () {
                this.root = document.querySelector(":root");
            },
            props: {
                changePageCallback: {
                    type: Function,
                    default: function (number, display, skipHistory = false) { console.log("This is supposed to change a page to page " + number + " and display query " + display + " and " + (skipHistory) ? "will" : "won't") + "skip pushing the history state"; }
                }
            },
            data: function () {
                return {
                    state: 0,
                    userEntry: null,
                    passEntry: null,
                    root: null,
                    timeout: null,
                    animationFrame: null,
                    loadingMessage: "Default loading message...",
                    effectsData: {
                        "drooping-bars": [],
                        "loading-bars": [],
                        "disc-rotations": [],
                    },
                    effectsInterval: null,
                    barSpeeds: [0.25, 0.5, 1, 2, 4, 5, 10, 15, 20, 25, 50],
                    sequenceMessages:
                        [
                            "Hacking Planet",
                            "Downloading More RAM",
                            "Deleting Internet",
                            "Firing Laser",
                            "Booting VMU",
                            "Warming Thrusters",
                            "Herding Cats",
                            "Uploading \"evil-plans.txt\"",
                            "Shutting Down Computer",
                            "Initiating Rotary Attack",
                            "Restoring Mana",
                            "Polishing Jade Sword",
                            "Venting Radioactive Gas",
                            "Deploying Robot Masters",
                            "Calculating Sum of All Numbers",
                            "Destroying All Cosmos",
                            "Rolling Katamari",
                            "Eating Dots",
                            "Stooge Sorting",
                            "Writing Le Epic Meme",
                            "Feeding Chao",
                            "Playing, Sally...",
                            "Chasing Wild Geese",
                            "Creating More Explosions",
                            "Catching 'Em All",
                            "Really Feeling It",
                            "Deleting All Progress",
                            "Baking Donuts",
                            "Engaging Turtle Power Protocol",
                            "Turning Bunnies Into Badniks",
                            "Spinning Wheels",
                            "Commissioning a Symphony in C",
                            "Activating MEGAS",
                            "Recalling Warpstar",
                            "Circling Back Later",
                            "Beatboxing Puppy"
                        ],
                    magic8State: 0,
                    magic8Question: null,
                    magic8Query: "",
                    magic8Loading:
                        [
                            "Shuffling the tarot deck...",
                            "Rubbing the crystal ball...",
                            "Phoning Miss Cleo...",
                            "Asking the Decider...",
                            "Checking the writings of infinite monkeys...",
                            "Flipping a coin...",
                            "Calling Math.random()...",
                            "Sending a sbemail...",
                            "Reading some tea leaves...",
                            "Casting the runes...",
                            "Throwing bones...",
                            "Scanning palm...",
                            "Tuning into the weather channel...",
                            "Asking a psychic..."
                        ],
                    magic8LoadIndex: 0,
                    magic8Responses:
                        [
                            "It is certain",
                            "It is decidedly so",
                            "Without a doubt",
                            "Yes, definitely",
                            "You may rely on it",
                            "As I see it, yes",
                            "Most likely",
                            "Outlook good",
                            "Yes",
                            "Signs point to yes",
                            "Reply hazy",
                            "Ask again later",
                            "Better not tell you now",
                            "Cannot predict now",
                            "Concentrate and ask again",
                            "Don't count on it",
                            "My reply is no",
                            "My sources say no",
                            "Outlook not so good",
                            "Very doubtful"
                        ],
                    magic8ResponseIndex: 0,
                    recipe: {}
                };
            },
            methods:
            {
                checkEntryKeypress: function (keyEvent)
                {
                    if (keyEvent.key === "Enter")
                    {
                        this.checkCredentials();
                    }
                },
                checkCredentials: function ()
                {
                    this.userEntry = document.getElementById("username");
                    this.passEntry = document.getElementById("password");
                    if (this.userEntry && this.passEntry && this.root)
                    {
                        let user = this.userEntry.value;
                        let pass = this.passEntry.value;
                        this.passEntry = null;
                        if (user === "" || pass === ""){ 
                            this.dispatchAchievement("no-extra");
                            return;
                        }
                        this.state = 1;
                        this.loadingMessage = this.translate('loading-message-default');
                        if (user === "Trans rights" && pass === "human rights")
                        {
                            this.loadingMessage = this.translate('loading-message-tr4n5');
                            this.timeout = setTimeout(function () { this.setNewState(2); this.timeout = setTimeout(function(){this.setNewState(0)}.bind(this), 500 + Math.floor(Math.random()*8000)); }.bind(this), 2000 + Math.floor(Math.random() * 2000));
                            return;
                        }
                        if (user === "Ppppp" && pass === "pPpPp")
                        {
                            this.$store.commit("setFont", 3);
                            this.root.style.setProperty("--header-font", "PPPPP, sans-serif");
                            this.root.style.setProperty("--body-font", "PPPPP, sans-serif");
                            this.dispatchAchievement("ppppp");
                            this.timeout = setTimeout(function(){this.setNewState(0)}.bind(this), 500 + Math.floor(Math.random()*8000));
                            return;
                        }
                        if (user === "Lady Sia" && pass === "jadesword")
                        {
                            this.loadingMessage = this.translate('loading-message-secret');
                            this.populateBars();
                            this.timeout = setTimeout(function () { this.setNewState(3) }.bind(this), 2000 + Math.floor(Math.random() * 2000));
                            return;
                        }
                        if (user === "Project Shadow" && pass === "Maria")
                        {
                            this.$store.commit("setColorScale", 4);
                            this.root.style.setProperty("--header-bg", "#f82020");
                            this.root.style.setProperty("--content-bg", "#b0b0b0");
                            this.root.style.setProperty("--accent-color", "#f8a850");
                            this.root.style.setProperty("--font-color", "#303058");
                            this.$store.commit("setFont", 4);
                            this.root.style.setProperty("--header-font", "NSS, sans-serif");
                            this.root.style.setProperty("--body-font", "NSSU, sans-serif");
                            this.loadingMessage = this.translate('loading-message-s3g4');
                            this.dispatchAchievement("cover-letter");
                            this.timeout = setTimeout(function () { this.setNewState(4) }.bind(this), 2000 + Math.floor(Math.random() * 2000));
                            return;
                        }
                        if (user === "What's for dessert?" && pass === "Pudding!")
                        {
                            this.loadingMessage = this.translate('loading-message-food');
                            this.dispatchAchievement("foodie");
                            fetch('./assets/data/secret-recipe.json').then((response) => { return response.json() }).then((data) => { this.recipe = data; this.timeout = setTimeout(function () { this.setNewState(5) }.bind(this), 2000 + Math.floor(Math.random() * 2000))});
                            return;
                        }
                        if (user === "Roselia" && pass === "pd#315")
                        {
                            this.loadingMessage = this.translate('loading-message-p0k3');
                            this.dispatchAchievement("cover-letter");
                            this.timeout = setTimeout(function () { this.setNewState(6) }.bind(this), 2000 + Math.floor(Math.random() * 2000));
                            return;
                        }
                        if (user === "Magic-8BT" && pass === "disruptingthefutureofai")
                        {
                            this.loadingMessage = this.translate('loading-message-magic');
                            this.timeout = setTimeout(function () { this.setNewState(8) }.bind(this), 2000 + Math.floor(Math.random() * 4000));
                            return;
                        }
                        if (user === "Seeker" && pass === "AskAndYouShallReceive")
                        {
                            this.loadingMessage = this.translate('loading-message-game');
                            this.$store.commit("setActiveGame", "seeker");
                            this.timeout = setTimeout(function () { this.changePageCallback(6, "seeker") }.bind(this), 4000 + Math.floor(Math.random() * 8000));
                            return;
                        }
                        if (user === "9+10" && pass === "21")
                        {
                            this.loadingMessage = this.translate('loading-message-game');
                            this.timeout = setTimeout(function () { this.changePageCallback(8, "blackjack") }.bind(this), 2000 + Math.floor(Math.random() * 4000));
                            return;
                        }
                        if (user === "Chrona Machina" && pass === "Stranger Space")
                        {
                            this.timeout = setTimeout(function(){this.$store.commit("setMusicPlayer", true); this.setNewState(0);}.bind(this), 1000 + Math.floor(Math.random() *4000));
                            this.dispatchAchievement("true-fan");
                            return;
                        }
                        this.timeout = setTimeout(function () { this.setNewState(100); this.dispatchAchievement("no-extra"); this.timeout = setTimeout(function(){this.setNewState(0)}.bind(this), 500 + Math.floor(Math.random()*8000)); }.bind(this), 1000 + Math.floor(Math.random() * 1000));
                    }
                },
                setNewState: function (state)
                {
                    this.state = -1;
                    this.timeout = setTimeout(function () {
                        this.state = state;
                        switch (state)
                        {
                            case 2:
                                this.loadingMessage = this.translate('loading-complete-tr4n5');
                                this.$store.commit("setColorScale", 3);
                                this.root.style.setProperty("--header-bg", "#F5A9B8");
                                this.root.style.setProperty("--content-bg", 'white');
                                this.root.style.setProperty("--accent-color", "#5BCEFA");
                                this.root.style.setProperty("--font-color", "#D90166");
                                break;
                            case 3: case 4: case 5: case 6:
                                break;
                            case 8:
                                this.startMagic8();
                                break;
                            default:
                                this.loadingMessage = this.translate('loading-complete-failure');
                                break;
                        }
                        this.timeout = null;
                    }.bind(this), 500);
                },
                populateBars: function ()
                {
                    for (let d = 0; d < 5; d++)
                    {
                        this.effectsData['disc-rotations'].push(Math.random() * 90);
                    }
                    let maxVBars = window.innerWidth / 12;
                    for (let v = 0; v < maxVBars; v++)
                    {
                        this.effectsData["drooping-bars"].push(
                            {
                                height: Math.floor(Math.random() * 100),
                                increase: Math.random() > 0.5,
                                speed: this.barSpeeds[Math.floor(Math.random() * this.barSpeeds.length)]
                            }
                        );
                    }
                    let numLBars = Math.max(4, Math.floor(Math.random() * 8) + 1);
                    let numMessages = Math.floor(this.sequenceMessages.length / numLBars);
                    for (let h = 0; h < numLBars; h++)
                    {
                        let messages = [];
                        for (let m = 0; m < numMessages; m++)
                        {
                            let index = Math.floor(Math.random() * this.sequenceMessages.length);
                            messages.push(this.sequenceMessages[index]);
                            this.sequenceMessages.splice(index, 1);
                        }
                        this.effectsData["loading-bars"].push(
                            {
                                width: 0,
                                speed: this.barSpeeds[Math.floor(Math.random() * this.barSpeeds.length)],
                                messages: messages,
                                index: Math.floor(Math.random() * messages.length)
                            }
                        );
                    }
                    this.effectsInterval = setInterval(this.updateEffects.bind(this), 500);
                },
                updateEffects: function ()
                {
                    for (let v = 0; v < this.effectsData["drooping-bars"].length; v++)
                    {
                        let bar = this.effectsData["drooping-bars"][v];
                        bar.height = this.clamp(bar.height + (bar.increase ? bar.speed : -bar.speed), 0, 100);
                        if ((bar.height === 100 && bar.increase) || (bar.height === 0 && !bar.increase))
                        {
                            bar.increase = !bar.increase;
                            bar.speed = this.barSpeeds[Math.floor(Math.random() * this.barSpeeds.length)];
                        }
                    }
                    for (let h = 0; h < this.effectsData["loading-bars"].length; h++)
                    {
                        let bar = this.effectsData["loading-bars"][h];
                        if (bar.width === 100) {
                            if (bar.messages[bar.index] === "Deleting All Progress")
                            {
                                for (let l = 0; l < this.effectsData["loading-bars"].length; l++) this.effectsData["loading-bars"][l].width = 0;
                            }
                            bar.width = 0;
                            bar.index++;
                            bar.index = bar.index % bar.messages.length;
                            bar.speed = this.barSpeeds[Math.floor(Math.random() * this.barSpeeds.length)];
                        }
                        else bar.width = Math.min(100, bar.width + bar.speed);
                    }
                },
                startMagic8: function ()
                {
                    if (this.magic8ReloadButton && this.magic8ReloadCallback)
                    {
                        this.magic8ReloadButton.removeEventListener("keypress", this.magic8ReloadCallback);
                        this.magic8ReloadButton = null;
                    }
                    this.magic8State = 0;
                    this.animationFrame = requestAnimationFrame(function () {
                        this.magic8Question = document.getElementById("question");
                        this.animationFrame = null;
                    }.bind(this));
                },
                checkMagic8Question: function ()
                {
                    if (this.magic8Question && this.magic8Question.value !== "")
                    {
                        this.magic8Query = this.magic8Question.value.trim();
                        if (this.magic8Query[this.magic8Query.length - 1] !== "?") this.magic8Query = this.magic8Query + "?";
                        this.magic8Question = null;
                        this.magic8EntryButton = null;
                        this.magic8LoadIndex = Math.floor(Math.random() * this.magic8Loading.length);
                        this.magic8State = 1;
                        this.timeout = setTimeout(function () {
                            this.magic8ResponseIndex = Math.floor(Math.random() * this.magic8Responses.length);
                            this.magic8State = 2;
                            this.timeout = null;
                        }.bind(this), 2000 + Math.floor(Math.random() * 4000));
                    }
                }
            },
            computed: {
                ...mapGetters({
                    colorScale: "getColorScale",
                    desktop: "getDesktop"
                })
            },
            umounted: async function () {
                if (this.timeout) clearTimeout(this.timeout);
                if (this.animationFrame) cancelAnimationFrame(this.animationFrame);
                if (this.effectsInterval) clearInterval(this.effectsInterval);
            }
        }
</script>
<style scoped lang="scss">
    .extras-container {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        text-align: center;

        .extras-content {
            position: relative;
            display: flex;
            width: fit-content;
            height: fit-content;
            padding: 8px 5%;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            font-family: var(--body-font);
            color: var(--font-color);
            font-size: font(16);

            video {
                position: relative;
                margin-top: 24px;
                width: 80%;
                height: auto;
            }

            .extras-title {
                position: relative;
                font-family: var(--header-font);
                font-weight: 700;
                margin-bottom: 8px;
                margin-top: 24px;
                text-transform: uppercase;
                margin-left: 8px;
                margin-right: 8px;
            }

            .extras-body {
                position: relative;
                margin: 8px;
            }

            .extras-subtitle {
                position: relative;
                font-family: var(--header-font);
                margin: 8px;
            }

            .extras-disclaimer {
                position: relative;
                font-size: font(8);
                margin-top: 64px;
                text-align: left;
                margin-bottom: 8px;
                margin-left: 8px;
                margin-right: 8px;
            }

            .extras-entry {
                position: relative;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                justify-items: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                align-self: center;

                .entry-title {
                    position: relative;
                    margin-right: 8px;
                }

                .entry-input {
                    position: relative;
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: var(--content-bg);
                    border: solid 2px var(--header-bg);
                    box-shadow: 2px 2px 2px var(--font-color);
                    height: font(16);
                    font-size: font(12);
                    flex-grow: 1;
                    font-family: var(--body-font);
                    width: auto;
                    color: var(--font-color);
                    align-self: center;

                    &:active, &:focus {
                        border: solid 2px var(--accent-color);
                        box-shadow: 1px 1px 1px var(--font-color);
                        outline: none;
                    }
                }

                margin-bottom: 8px;
            }

            .entry-button {
                box-shadow: 2px 2px 2px var(--font-color);
                background-color: var(--content-bg);
                border: solid 2px var(--header-bg);
                position: relative;
                pointer-events: all;
                width: fit-content;
                height: fit-content;
                padding: 8px 32px;
                display: flex;
                justify-items: center;
                justify-content: center;
                align-content: center;
                align-items: center;
                margin-left: 8px;
                margin-right: 8px;

                span {
                    position: relative;
                    font-size: font(24);
                    text-transform: uppercase;
                }

                &:active, &:hover {
                    background-color: var(--accent-color);
                }

                &:active {
                    box-shadow: 1px 1px 1px var(--font-color);
                }

                * {
                    pointer-events: none;
                }
            }

            .drooping-bars-container {
                margin-top: -8px;
                margin-bottom: 128px;
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: row;
                align-content: flex-start;
                align-items: flex-start;
                justify-items: center;
                justify-content: center;

                .bar-container {
                    margin: 0 4px;
                    position: relative;
                    width: 4px;
                    height: 256px;


                    .bar {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 50%;
                        transition: height linear 0.5s;
                        background-color: var(--accent-color);
                        box-shadow: 2px 2px 2px var(--font-color);
                    }
                }
            }

            .loading-bars-container {
                position: relative;
                height: fit-content;
                width: 75%;
                display: flex;
                flex-direction: column;
                justify-items: center;
                justify-content: center;
                align-content: flex-start;
                align-items: flex-start;

                .loading-section-container {
                    position: relative;
                    height: fit-content;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-items: center;
                    justify-content: center;
                    align-content: flex-start;
                    align-items: flex-start;

                    span {
                        position: relative;
                        text-align: left;
                        text-shadow: 1px 1px 1px var(--content-bg);
                    }

                    .loading-bar-container {
                        position: relative;
                        height: 16px;
                        width: 100%;
                        margin-bottom: 16px;
                        margin-top: 8px;
                        border: 4px solid var(--header-bg);
                        box-shadow: 2px 2px 2px var(--font-color);
                        background-color: var(--font-color);

                        .loading-bar {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 0%;
                            height: 100%;
                            background-color: var(--accent-color);
                            transition: width linear 0.5s;
                        }
                    }
                }
            }

            .rotating-disc {
                position: absolute;
                top: 100%;
                left: 100%;
                transform: translate(-50%, -50%) rotate(0);
                width: 256px;
                height: 256px;
                box-sizing: border-box;
                border-radius: 50%;
                background-clip: border-box;
                border: 96px solid var(--font-color);
                animation: disc-rotate linear infinite 4s;
                filter: drop-shadow(-2px 2px 2px var(--header-bg));

                &:after {
                    content: "";
                    width: 64px;
                    height: 64px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    box-sizing: border-box;
                    border-radius: 50%;
                    background-clip: border-box;
                    border: 24px solid var(--accent-color);
                }

                .disc-markings {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    box-sizing: border-box;
                    border-radius: 50%;
                    border: solid transparent 8px;
                    border-right-color: var(--content-bg);
                    border-left-color: var(--content-bg);
                    animation: none;
                }

                @keyframes disc-rotate {
                    0% {
                        transform: translate(-50%, -50%) rotate(0);
                    }

                    100% {
                        transform: translate(-50%, -50%) rotate(-360deg);
                    }
                }
            }

            .rotating-florp {
                position: absolute;
                top: 100%;
                left: 0;
                transform: translate(-50%, -50%) rotate(0);
                width: 992px;
                height: 992px;
                border-radius: 50%;
                background-clip: border-box;
                border: 16px solid var(--accent-color);
                animation: disc-rotate linear infinite 32s;
                filter: drop-shadow(2px 2px 2px var(--font-color));

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%);
                    width: 512px;
                    height: 16px;
                    background-color: var(--accent-color);
                }

                &:after {
                    content: "";
                    width: 48px;
                    height: 48px;
                    position: absolute;
                    top: 50%;
                    right: 90%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    border: 8px solid var(--accent-color);
                }

                animation: rotate-florp linear infinite 64s;

                @keyframes rotate-florp {
                    0% {
                        transform: translate(-75%, -10%) rotate(0);
                    }

                    100% {
                        transform: translate(-75%, -10%) rotate(360deg);
                    }
                }
            }

            .recipe-container {
                margin-top: 8px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;

                .ingredients-container {
                    padding: 4px 8px;
                    background-color: var(--accent-color);
                }

                .ingredients-container, .variations-container, .ingredients-container {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-content: flex-start;
                    align-items: flex-start;
                    margin: 8px 0;
                }
            }
        }
    }
</style>